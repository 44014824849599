import { Carousel, Drawer } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"

const APPBAR_HEIGHT = 60;

export function Home() {
    const [showMenu, setShowMenu] = useState(false)
    const [comingSoon, setCommingSoon] = useState(false)
    const [showOperatorInfo, setOperatorInfo] = useState(false)
    const [searchParams] = useSearchParams()

    const refFs = useRef<null[] | HTMLDivElement[]>([])
    const refContent = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const windowWidth = window.innerWidth;

        const meta = document.createElement('meta');
        meta.name = "viewport";
        if (windowWidth <= 480) {
            meta.content = "width=480"
        } else {
            meta.content = "width=device-width, initial-scale=1.0";
        }
        document.getElementsByTagName('head')[0].appendChild(meta);

        const op = searchParams.get("op")
        if (op && op.length > 0) {
            onShowOperatorInfo()
        }
    }, [])

    function onClickShowCase() {
        window.open("https://voltaic-squash-54e.notion.site/dee3cd2ddb8642f8840f716dca483142")
    }

    function onClickPressRelease() {
        window.open("https://voltaic-squash-54e.notion.site/Press-Release-9c051460574c49b68c725d1ac607a019")
    }

    function onClickBusinessPartnerInquiry() {
        window.open("https://forms.gle/mZdyPcznrCkTXCuC9")
    }

    function onShowMenu() {
        setShowMenu(true)
        setCommingSoon(false)
        setOperatorInfo(false)
    }

    function onHideMenu() {
        setShowMenu(false)
    }

    function onShowCommingSoon() {
        setShowMenu(false)
        setCommingSoon(true)
        setOperatorInfo(false)
    }

    function onHideCommingSoon() {
        setCommingSoon(false)
    }

    function onShowOperatorInfo() {
        setShowMenu(false)
        setCommingSoon(false)
        setOperatorInfo(true)
    }

    function onHideOperatorInfo() {
        setOperatorInfo(false)
    }

    function onClickMenu(evt: React.MouseEvent, fIdx: number) {
        evt.stopPropagation()
        evt.preventDefault()

        // window.scrollTo({ behavior: "smooth", top: refFs.current[fIdx]!.offsetTop - APPBAR_HEIGHT })
        refContent.current!.scrollTo({ behavior: "smooth", top: refFs.current[fIdx]!.offsetTop - APPBAR_HEIGHT })

        onHideMenu()
    }

    function renderMenuItem(imgSrc: string, title: string, onClick: (evt: React.MouseEvent) => void) {
        return <>
            <div className="w-[350px] h-[59px] relative cursor-pointer" onClick={onClick}>
                <img src={`/static/menuicon/${imgSrc}`} alt="" className="w-[24px] h-[24px] absolute top-[21px] left-[20px]" />
                <span className="absolute left-[67px] top-[13px] font-noto text-[24px] text-black text-left font-[500]">{title}</span>
            </div >
        </>
    }

    function onScrollMenu(evt: React.UIEvent) {
        evt.stopPropagation()
        evt.preventDefault()


    }

    function renderMenu() {
        return <>
            {/* <div className="fixed w-screen h-screen flex justify-center z-[2]">
                <div className="w-app relative overflow-hidden"> */}
            <div className="w-app fixed h-screen overflow-hidden z-[2]" onScroll={onScrollMenu} >
                <Drawer title={null} onClose={onHideMenu} open={showMenu} width={350} getContainer={false} styles={{ header: { "display": "none" }, body: { "padding": "0px" } }}>
                    <div className="relative overflow-y-auto">
                        <div className="h-[72px]">&nbsp;</div>
                        {renderMenuItem("icon_question.png", "우리는 어떤 회사?", evt => onClickMenu(evt, 1))}
                        {renderMenuItem("icon_company.png", "유엘이 걸어온 길", evt => onClickMenu(evt, 2))}
                        {renderMenuItem("icon_dollar.png", "사업 영역", evt => onClickMenu(evt, 3))}
                        {renderMenuItem("icon_internet.png", "메트로 인터넷 서비스", evt => onClickMenu(evt, 4))}
                        {renderMenuItem("icon_vpn.png", "VPN 서비스", evt => onClickMenu(evt, 5))}
                        {renderMenuItem("icon_networkservice.png", "통신 네트워크 관리", evt => onClickMenu(evt, 6))}
                        {renderMenuItem("icon_nextservice.png", "차세대 서비스", evt => onClickMenu(evt, 7))}
                        {renderMenuItem("icon_partners.png", "Partners", evt => onClickMenu(evt, 8))}
                        <div className="h-[20px]">&nbsp;</div>
                        <div className="w-full h-[2px] flex items-center justify-center">
                            <div className="w-[95%] h-full bg-[#dedede]">&nbsp;</div>
                        </div>
                        {renderMenuItem("icon_envelope.png", "사업 및 제휴 문의", onClickBusinessPartnerInquiry)}
                        {renderMenuItem("icon_megaphone.png", "Press Release", onClickPressRelease)}
                        {renderMenuItem("icon_loadmap.png", "오시는  길", evt => onClickMenu(evt, 9))}
                        <div className="h-[32px]">&nbsp;</div>
                    </div>
                </Drawer>
            </div>
            {/* </div>
            </div> */}
        </>

        // return <>
        //     <div className="flex justify-center" onClick={onHideMenu}>
        //         <div className="w-app fixed bg-[#cdcdcd80] z-[2]">
        //             <div className="w-full h-full">
        //                 <div className="w-[300px] relative left-[180px] bg-[gray] overflow-y-auto">
        //                     <div className="h-[72px]">&nbsp;</div>
        //                     {renderMenuItem("icon_question.png", "우리는 어떤 회사?", evt => onClickMenu(evt, 1))}
        //                     {renderMenuItem("icon_company.png", "유엘이 걸어온 길", evt => onClickMenu(evt, 2))}
        //                     {renderMenuItem("icon_dollar.png", "사업 영역", evt => onClickMenu(evt, 3))}
        //                     {renderMenuItem("icon_internet.png", "메트로 인터넷 서비스", evt => onClickMenu(evt, 4))}
        //                     {renderMenuItem("icon_vpn.png", "VPN 서비스", evt => onClickMenu(evt, 5))}
        //                     {renderMenuItem("icon_networkservice.png", "통신 네트워크 관리", evt => onClickMenu(evt, 6))}
        //                     {renderMenuItem("icon_nextservice.png", "차세대 서비스", evt => onClickMenu(evt, 7))}
        //                     {renderMenuItem("icon_partners.png", "Partners", evt => onClickMenu(evt, 8))}
        //                     <div className="h-[20px]">&nbsp;</div>
        //                     <div className="w-full h-[2px] flex items-center justify-center">
        //                         <div className="w-[95%] h-full bg-[#dedede]">&nbsp;</div>
        //                     </div>
        //                     {renderMenuItem("icon_envelope.png", "사업 및 제휴 문의", onClickBusinessPartnerInquiry)}
        //                     {renderMenuItem("icon_megaphone.png", "Press Release", onClickPressRelease)}
        //                     {renderMenuItem("icon_loadmap.png", "오시는  길", evt => onClickMenu(evt, 9))}
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>
    }

    function renderCommingSoon() {
        return <>
            <div className="flex justify-center " onClick={onHideCommingSoon}>
                <div className="w-app h-full fixed top-0 bg-[#cdcdcd80] z-[2]" onClick={onHideCommingSoon}>
                    <div className="w-full h-full relative">
                        <div className="w-[340px] h-[545px] absolute top-[128px] left-[70px] bg-[url('../public/static/commingsoon.png')] bg-cover bg-center bg-no-repeat">
                            <span className="absolute top-[39px] left-[41px] font-noto text-[30px] font-[900] text-white">
                                COMMING SOON
                            </span>
                            <span className="w-full absolute top-[94px] font-noto text-[20px] text-white text-center">
                                유엘의 새로운 VPN 서비스가<br />
                                여러분을 찾아 갑니다!
                            </span>
                            <div className="absolute w-[287px] h-[50px] bg-[#3E5FFF] rounded-[9999px] top-[436px] left-[27px] text-white font-noto text-[22.7px] flex items-center justify-center cursor-pointer" onClick={onHideCommingSoon}>
                                네, 확인했어요
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    }

    function renderOperatorInfo() {
        return <>
            <div className="flex justify-center " onClick={onHideCommingSoon}>
                <div className="w-app h-full fixed top-0 bg-[#cdcdcd80] z-[2] overflow-auto" onClick={onHideOperatorInfo}>
                    <div className="w-full h-full relative">
                        <img src="/static/operatorinfo/a.jpg" alt="" className="mt-[20px] ml-[15px] w-[450px] h-[630px]" />
                        <img src="/static/operatorinfo/b.jpg" alt="" className="mt-[20px] ml-[15px] w-[450px] h-[630px]" />
                        <img src="/static/operatorinfo/c.jpg" alt="" className="mt-[20px] ml-[15px] w-[450px] h-[630px]" />
                        <img src="/static/operatorinfo/d.jpg" alt="" className="mt-[20px] ml-[15px] w-[450px] h-[630px]" />
                        <img src="/static/operatorinfo/e.jpg" alt="" className="mt-[20px] ml-[15px] w-[450px] h-[630px]" />
                    </div>
                </div>
            </div>
        </>
    }

    function renderAppBar() {
        return <>
            <div className="w-app h-appbar fixed top-0 z-[1] bg-white">
                <div className="w-full h-full relative flex items-center justify-center">
                    <img src="/static/logo2.png" alt="" className="w-[62px] h-[52px] cursor-pointer" onClick={evt => onClickMenu(evt, 0)} />
                    <img src="/static/menu.png" alt="" className="w-[53px] h-[60px] absolute right-[20px] cursor-pointer" onClick={onShowMenu} />
                </div>
            </div>
        </>
    }

    function renderF1() {
        return <>
            <div ref={el => refFs.current[0] = el}>
                <Carousel autoplay autoplaySpeed={3000} dots={false}>
                    <div className="w-full flex justify-center">
                        <div className="relative w-[480px] h-[780px] bg-[url('../public/static/home/f1-a.jpg')]">
                            <span className="w-full absolute flex justify-center top-[113px] font-noto text-[35px] text-white font-[600] leading-[40px]">
                                공간과 공간을 연결합니다
                            </span>
                            <span className="w-full absolute flex justify-center bottom-[61px] font-noto text-[22px] text-white text-center">
                                유엘네트웍스는 <br />
                                기가통신망과 연결된 자체 백본망으로<br />
                                안정적인 초고속 통신 서비스를 제공합니다<br />
                            </span>
                        </div>
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="relative w-[480px] h-[780px] bg-[url('../public/static/home/f1-b.jpg')]">
                            <span className="w-full absolute flex justify-center top-[113px] font-noto text-[35px] text-white font-[600] leading-[40px]">
                                현실과 가상을 연결합니다
                            </span>
                            <span className="w-full absolute flex justify-center bottom-[61px] font-noto text-[22px] text-white text-center">
                                유엘네트웍스는<br />
                                자체 IDC를 통해 콘텐츠사업자와<br />
                                사용자간 네트워크 연결을 지원합니다
                            </span>
                        </div>
                    </div>
                    <div className="w-full flex justify-center">
                        <div className="relative w-[480px] h-[780px] bg-[url('../public/static/home/f1-c.jpg')]">
                            <span className="w-full absolute flex justify-center top-[113px] font-noto text-[35px] text-white font-[600] leading-[40px]">
                                일상과 즐거움을 연결합니다
                            </span>
                            <span className="w-full absolute flex justify-center bottom-[61px] font-noto text-[22px] text-white text-center">
                                유엘네트웍스는<br />
                                자체 네트워크 장비와 인프라, 공인 IP를 통해<br />
                                단순한 인터넷이 아닌 엔터넷을 지향합니다.<br />
                            </span>
                        </div>
                    </div>
                </Carousel>
            </div>
        </>
    }

    function renderF2() {
        return <>
            <div ref={el => refFs.current[1] = el} className="w-full flex justify-center">
                <div className="relative w-[480px] h-[780px] bg-[url('../public/static/home/f2.jpg')]">
                    <span className="w-full absolute flex justify-center top-[500px] font-noto text-[40px] text-white text-center font-[600] leading-[50px]">
                        30년 네트워크 기술의<br />
                        노하우
                    </span>
                    <span className="w-full absolute flex justify-center bottom-[54px] font-noto text-[22px] text-white text-center">
                        네트워크 서비스 운영에서 장비 유지보수 까지,<br />
                        유엘은 작지만 강한 토털 인터넷 솔루션을<br />
                        제공합니다
                    </span>
                </div>
            </div>
        </>
    }

    function renderF3() {
        return <>
            <div ref={el => refFs.current[2] = el} className="w-full flex justify-center">
                <div className="relative w-[480px] h-[780px] bg-[url('../public/static/home/f3.jpg')]">
                    <span className="w-full absolute flex justify-center top-[19px] font-noto text-[40px] text-white text-center font-[600] leading-[50px]">
                        회사 연혁
                    </span>
                    <img src="/static/home/f3-history.png" alt="" className="absolute w-[466px] h-[658px] top-[98px] left-[5px]" />
                    {/* <img src="/static/home/f3-vertical-timeline.png" alt="" className="absolute w-[32px] h-[664px] top-[98px] left-0" />
                    <span className="w-full absolute top-[88px] left-[41px] font-noto text-[23px] text-white">2007년도 (주)유엘네트웍스 설립</span>
                    <span className="w-full absolute top-[157px] left-[41px] font-noto text-[23px] text-white">2008년도 정보통신 공사업, VPN 서비스 개시</span>
                    <span className="w-full absolute top-[225px] left-[41px] font-noto text-[23px] text-white">2009년도 통신망 관제 및 유비보수 서비스 개시</span>
                    <span className="w-full absolute top-[292px] left-[41px] font-noto text-[23px] text-white">2010년도 IDC (서버호스팅) 서비스 개시</span>
                    <span className="w-full absolute top-[356px] left-[41px] font-noto text-[23px] text-white">2011년도 KT 무선기지국 협력 업체</span>
                    <span className="w-full absolute top-[427px] left-[41px] font-noto text-[23px] text-white">2012년도 개인서비스 개시</span>
                    <span className="w-full absolute top-[491px] left-[41px] font-noto text-[23px] text-white">2014년도 KTS (북부, 남부) 협력사</span>
                    <span className="w-full absolute top-[561px] left-[41px] font-noto text-[23px] text-white">2015년도 SKT 협력업체, 가좌지사 개설</span>
                    <span className="w-full absolute top-[627px] left-[41px] font-noto text-[23px] text-white">2020년도 홍제사무실 확장 이전</span> */}
                </div>
            </div>
        </>
    }

    function renderF4() {
        return <>
            <div ref={el => refFs.current[3] = el} className="w-full flex justify-center">
                <div className="relative w-[480px] h-[780px] bg-[url('../public/static/home/f4.jpg')]">
                    <img src="/static/home/f4-biz.png" alt="" className="absolute w-[408px] h-[348px] top-[20px] left-[-10px]" />
                </div>
            </div>
        </>
    }

    function renderF5() {
        return <>
            <div ref={el => refFs.current[4] = el} className="w-full flex justify-center">
                <div className="relative w-[480px] h-[780px] bg-[url('../public/static/home/f5.jpg')]">
                    <img src="/static/home/f5-internet.png" alt="" className="absolute w-[443px] h-[395px] top-[290px] left-[18.5px]" />
                    <span className="w-full absolute flex justify-center top-[62px] font-noto text-[40px] text-white text-center font-[600] leading-[50px]">
                        메트로 인터넷 서비스
                    </span>
                    <span className="w-full absolute flex justify-center top-[149px] font-noto text-[22px] text-white text-center">
                        모든 회선은 KT 광케이블로 연결되어 있으며,<br />
                        P-t-P 방식의 안정적인 구조를 가지고 있습니다.
                    </span>
                </div>
            </div>
        </>
    }

    function renderF6() {
        return <>
            <div ref={el => refFs.current[5] = el} className="w-full flex justify-center">
                <div className="relative w-[480px] h-[780px] bg-[url('../public/static/home/f6.jpg')]">
                    <img src="/static/home/f6-internet.png" alt="" className="absolute w-[443px] h-[361px] top-[344px] left-[18.5px]" />
                    <span className="w-full absolute flex justify-center top-[62px] font-noto text-[40px] text-[#111111] text-center font-[700] leading-[50px]">
                        VPN 서비스
                    </span>
                    <span className="w-full absolute flex justify-center top-[149px] font-noto text-[22px] text-[#111111] text-center">
                        KT 광케이블과 연결된 초고속 인터넷환경,<br />
                        국내 공인 IP 사용으로 안정적이고 빠른<br />
                        네트워크 속도를 보장합니다.
                    </span>
                </div>
            </div>
        </>
    }

    function renderF7() {
        return <>
            <div ref={el => refFs.current[6] = el} className="w-full flex justify-center">
                <div className="relative w-[480px] h-[780px] bg-[url('../public/static/home/f7.jpg')]">
                    <span className="w-full absolute flex justify-center top-[62px] font-noto text-[40px] text-white text-center font-[600] leading-[50px]">
                        통신 네트워크 관리
                    </span>
                    <span className="w-full absolute flex justify-center top-[119px] font-noto text-[22px] text-white text-center">
                        기업용 전산 네트워크 구축에서 부터 유지보수,<br />
                        네트워크 전문기업인 유엘이 책임집니다.
                    </span>

                    <img src="/static/home/f7-11.jpg" alt="" className="absolute w-[142px] h-[222px] top-[204px] left-[9px]" />
                    <img src="/static/home/f7-12.jpg" alt="" className="absolute w-[142px] h-[222px] top-[204px] left-[169px]" />
                    <img src="/static/home/f7-13.jpg" alt="" className="absolute w-[142px] h-[222px] top-[204px] left-[329px]" />
                    <img src="/static/home/f7-21.jpg" alt="" className="absolute w-[142px] h-[222px] top-[475px] left-[9px]" />
                    <img src="/static/home/f7-22.jpg" alt="" className="absolute w-[142px] h-[222px] top-[475px] left-[169px]" />
                    <img src="/static/home/f7-23.jpg" alt="" className="absolute w-[142px] h-[222px] top-[475px] left-[329px]" />

                    <div className="absolute w-[287px] h-[50px] bg-[#3E5FFF] rounded-[9999px] top-[714px] left-[96.5px] text-white font-noto text-[22.7px] flex items-center justify-center cursor-pointer" onClick={onClickShowCase}>
                        설치 및 구축 사례 보기
                    </div>
                </div>
            </div>
        </>
    }

    function renderF8() {
        return <>
            <div ref={el => refFs.current[7] = el} className="w-full flex justify-center">
                <div className="relative w-[480px] h-[780px] bg-[url('../public/static/home/f8.jpg')]">
                    <span className="w-full absolute flex justify-center top-[62px] font-noto text-[40px] text-white text-center font-[600] leading-[50px]">
                        차세대 서비스
                    </span>
                    <span className="w-full absolute flex justify-center top-[149px] font-noto text-[22px] text-white text-center">
                        차세대 WireGuard 기술을 적용한 새로운<br />
                        VPN 서비스 ‘UP&’과 공인 IP 기반의<br />
                        구독형 프리미엄 게임 서비스 ‘MG Zone’을<br />
                        통한 차세대 부가서비스를 준비하고 있습니다.
                    </span>

                    {/* up& */}
                    <div className="absolute top-[345px] left-[19px] w-[443px] h-[89px] bg-[url('../public/static/home/f8-up&.png')] cursor-pointer" onClick={onShowCommingSoon}>
                        <img src="/static/home/f8-up&character.png" alt="" className="absolute w-[128px] h-[80px] top-[5px] left-[32px]" />
                        <span className="absolute top-[28px] right-[51px] font-noto text-[22.7px] text-white text-center">
                            UP& 사이트로 이동
                        </span>
                    </div>

                    {/* mobile gaming zone */}
                    <div className="absolute top-[494px] left-[19px] w-[443px] h-[89px] bg-[url('../public/static/home/f8-gaming-zone.png')] cursor-pointer" onClick={onShowCommingSoon}>
                    </div>
                </div>
            </div>
        </>
    }

    function renderF9() {
        return <>
            <div ref={el => refFs.current[8] = el} className="w-full flex justify-center">
                <div className="relative w-[480px] h-[800px] bg-[url('../public/static/home/f9-top.png')] bg-no-repeat border-solid border-b-[1px] border-b-[#CBCBCB]">
                    <span className="w-full absolute flex justify-center top-[62px] font-noto text-[40px] text-black text-center font-[900] leading-[50px]">
                        Partners
                    </span>

                    <img src="/static/home/f9-partners.png" alt="" className="absolute top-[195px] left-[-5px] w-[487px] h-[470px]" />
                    {/* 
                    <img src="/static/home/f9-kt.png" alt="" className="absolute top-[166px] left-[7px] w-[94px] h-[89px]" />
                    <img src="/static/home/f9-qrix.png" alt="" className="absolute top-[166px] left-[146px] w-[156px] h-[89px]" />
                    <img src="/static/home/f9-networkbox.png" alt="" className="absolute top-[166px] left-[336px] w-[114px] h-[89px]" />

                    <img src="/static/home/f9-watchguard.png" alt="" className="absolute top-[292px] left-[9px] w-[207px] h-[68px]" />
                    <img src="/static/home/f9-hitel.png" alt="" className="absolute top-[292px] left-[241px] w-[241px] h-[68px]" />

                    <img src="/static/home/f9-gameassociation.png" alt="" className="absolute top-[397px] left-[121px] w-[207px] h-[69px]" /> */}
                </div>
            </div>
        </>
    }

    function renderF10() {
        return <>
            <div ref={el => refFs.current[9] = el} className="w-full flex justify-center">
                <div className="relative w-[480px] h-[800px]">
                    <span className="w-full absolute flex justify-center top-[62px] font-noto text-[40px] text-black text-center font-[600] leading-[50px]">
                        오시는 길
                    </span>
                    <img src="/static/home/f10-loadmap.png" alt="" className="absolute top-[120px] left-[0] w-[480px] h-[368px]" />
                    <img src="/static/home/f10-loadmap2.png" alt="" className="absolute top-[488px] left-[0] w-[496px] h-[291px]" />
                </div>
            </div>
        </>
    }

    function renderFooter() {
        return <>
            <div className="relative w-[480px] h-[227px] bg-[#494949]">
                <div className="absolute top-[70px] left-[376px] cursor-pointer">
                    <img src="/static/home/footer_businessinfo_bg.png" alt="" className="w-[86px] h-[21px]" />
                    <span className="absolute top-[3px] left-[6px] font-nanum text-[10px] leading-[16px] tracking-[-1px] text-[#555555]" onClick={onShowOperatorInfo}>사업자정보 확인</span>
                </div>

                <img src="/static/home/footer_pseudo.png" alt="" className="absolute left-[60px] w-[360px] h-[4px]" />
                {/* row */}
                <div className="absolute top-[33px] left-[20px] font-nanum text-[#bdbdbd] text-[10px] leading-[25px] tracking-[0px]">
                    (주)유엘네트웍스 대표자 : 정재순 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 사업자등록번호 110-81-79327<br />
                    별정통신사업자 등록번호 제 110952 호<br />
                    서울시 서대문구 통일로 25길 30, 상가동 303호<br />
                    고객지원 (평일 9시-18시) : Tel) 02-396-0100 | kj.joung@ullms.co.kr<br />
                </div>
                {/* row */}
                <div className="absolute top-[200px] left-[20px] font-nanum text-[#bdbdbd] text-[10px] leading-[20px] tracking-[0px]">
                    Copyrights © ULNetworks.Inc  All rights reserved.
                </div>
            </div>
        </>
    }

    function renderContent() {
        return <>
            {renderF1()}
            <div className="h-[20px]">&nbsp;</div>
            {renderF2()}
            <div className="h-[20px]">&nbsp;</div>
            {renderF3()}
            <div className="h-[20px]">&nbsp;</div>
            {renderF4()}
            <div className="h-[20px]">&nbsp;</div>
            {renderF5()}
            <div className="h-[20px]">&nbsp;</div>
            {renderF6()}
            <div className="h-[20px]">&nbsp;</div>
            {renderF7()}
            <div className="h-[20px]">&nbsp;</div>
            {renderF8()}
            <div className="h-[20px]">&nbsp;</div>
            {renderF9()}
            <div className="h-[20px]">&nbsp;</div>
            {renderF10()}
            <div className="h-[20px]">&nbsp;</div>
            {renderFooter()}
        </>
    }

    return <>
        <div ref={refContent} className="h-screen bg-white flex relative justify-center overflow-auto">
            <div className={`w-app h-screen`}>
                {showMenu && renderMenu()}
                {renderAppBar()}
                {renderContent()}
            </div>
        </div>
        {/* {showMenu && renderMenu()} */}
        {comingSoon && renderCommingSoon()}
        {showOperatorInfo && renderOperatorInfo()}
    </>
}
